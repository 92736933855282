import { ref, Ref, reactive } from 'vue';
import { byteLength } from 'byte-length';
import { checkCard, checkDeliveryPIN } from '@/methods/rules/access';
import { access } from '@/data';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import { AddFormDataType } from '../all.type';

const initData = (opera: 'add' | 'edit', type: 'pin' | 'rfCard') => {
    const date = new Date();
    // form相关操作
    const formRef: Ref<any> = ref();
    const formData: AddFormDataType = reactive({
        ID: '',
        Name: '',
        Key: '',
        SchedulerType: type === 'pin' ? '0' : '1',
        DateFlag: [],
        StartTime: type === 'pin' ? '' : new Date(date.getFullYear(), date.getMonth(), date.getDay(), 0, 0, 0),
        StopTime: type === 'pin' ? '' : new Date(date.getFullYear(), date.getMonth(), date.getDay(), 23, 59, 59),
        BeginTime: '',
        EndTime: '',
        MAC: [],
        Devices: []
    });

    if (opera === 'edit') {
        const data: AddFormDataType = JSON.parse(localStorage.getItem('propertyKeyDetail') as string);
        formData.ID = data.ID;
        formData.Name = data.Name;
        formData.Key = data.Code!;
        formData.SchedulerType = data.SchedulerType;
        formData.StartTime = new Date(`2000-01-01 ${data.StartTime}`);
        formData.StopTime = new Date(`2000-01-01 ${data.StopTime}`);
        formData.BeginTime = data.BeginTime === '0000-00-00 00:00:00' ? '' : data.BeginTime;
        formData.EndTime = data.EndTime === '0000-00-00 00:00:00' ? '' : data.EndTime;
        if (typeof data.DateFlag === 'string' && data.DateFlag.length > 0) {
            formData.DateFlag = data.DateFlag.split(';');
        }
        formData.Devices = data.Devices;
    }

    const repeatOptions = [{
        label: WordList.ProperAllTextNever,
        value: '0'
    }, {
        label: WordList.ProperAllTextDaily,
        value: '1'
    }, {
        label: WordList.ProperAllTextWeekly,
        value: '2'
    }];

    const headers: ListHeaderItem[] = [{
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        type: 'customize',
        headerType: 'customize'
    }];

    const requestData: RequestData = {
        url: 'v3/web/community/device/getPubListForPm',
        param: {}
    };

    return {
        formRef,
        formData,
        repeatOptions,
        headers,
        requestData
    };
};

const checkDeliveryName: RuleMethod = (rule, value, callback) => {
    if (byteLength(value as string) > 63) {
        return callback(
            new Error(
                WordList.RuleLengthExceeds63.format(
                    WordList.TabelPersonUserInHtmlName,
                    WordList.TabelPersonUserInHtmlName
                )
            )
        );
    }
    return callback();
};

const getFormRules = (schedulerType: string, type: 'pin' | 'rfCard') => {
    const baseRules = {
        Name: [{
            required: true,
            message: WordList.RuleNameEmpty,
            trigger: 'blur'
        }, {
            validator: checkDeliveryName,
            trigger: 'blur'
        }],
        Key: [{
            required: true,
            message: WordList.RuleKeyEmpty,
            trigger: 'blur'
        }, {
            validator: type === 'pin' ? checkDeliveryPIN : checkCard,
            trigger: 'blur'
        }]
    };
    const neverRules = {
        BeginTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlBeginTime),
            trigger: 'blur'
        }],
        EndTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
    const dailyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
    const weeklyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }],
        DateFlag: [{
            required: true,
            message: WordList.ProperAllTextDayRequired,
            trigger: 'blur'
        }]
    };
    if (schedulerType === access.never) {
        return {
            ...baseRules,
            ...neverRules
        };
    }
    if (schedulerType === access.daily) {
        return {
            ...baseRules,
            ...dailyRules
        };
    }
    // weekly
    return {
        ...baseRules,
        ...weeklyRules
    };
};

export default initData;
export {
    getFormRules
};
