
import {
    computed,
    defineComponent, PropType, ref, watch
} from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import pinEncryptInput from '@/components/common/pin-encrypt-input/index.vue';
import { list } from '@/components/common/list';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import { access } from '@/data';
import initCheck from '@/methods/relay-choose';
import { format } from '@/methods/date';
import Notice from '@/util/notice.base';
import { deliveryAuthApi } from '@/api';
import initData, { getFormRules } from './add';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        pinEncryptInput,
        list,
        deviceIcon
    },
    props: {
        type: {
            type: String as PropType<'pin' | 'rfCard'>,
            default: 'pin'
        },
        opera: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        }
    },
    setup(props) {
        const breadHeaderLabel = {
            add: {
                pin: WordList.DeliveryAddPKey,
                rfCard: WordList.DeliveryAddRCard
            },
            edit: {
                pin: WordList.ProperAllTextEditPin,
                rfCard: WordList.DeliveryEditRCard
            }
        };
        const breadHeaders = [{
            label: WordList.DeliveryDeliveryAuth,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldDeliveryAuth}?type=${props.type}`
        }, {
            label: breadHeaderLabel[props.opera][props.type]
        }];

        const {
            formRef,
            formData,
            repeatOptions,
            headers,
            requestData
        } = initData(props.opera, props.type);

        // 监听切换模式，加载不同rules
        const rules = ref(getFormRules(formData.SchedulerType, props.type));
        watch(computed(() => formData.SchedulerType), () => {
            rules.value = getFormRules(formData.SchedulerType, props.type);
        });

        const {
            allCheck, checkArray, dealList, choose, getCommit, changeAllCheck, setCheck
        } = initCheck();

        const update = ref(0);
        if (props.opera === 'edit') {
            setCheck(formData.Devices, update);
        }

        const submit = () => {
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox('alert', WordList.RuleMacChoose, WordList.PersonuserInfo, 'info')(() => false);
                return false;
            }
            const url = {
                pin: {
                    add: deliveryAuthApi.addDeliveryPin,
                    edit: deliveryAuthApi.editDeliveryPin
                },
                rfCard: {
                    add: deliveryAuthApi.addDeliveryRfCard,
                    edit: deliveryAuthApi.editDeliveryRfCard
                }
            };
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const param = { ...formData };
                    if (param.SchedulerType === access.weekly && typeof param.DateFlag !== 'string') {
                        param.DateFlag = param.DateFlag.join(';');
                    }
                    param.BeginTime = format(param.BeginTime, 'YYYY-MM-DD HH:mm:ss');
                    param.EndTime = format(param.EndTime, 'YYYY-MM-DD HH:mm:ss');
                    param.StartTime = format(param.StartTime, 'HH:mm:ss');
                    param.StopTime = format(param.StopTime, 'HH:mm:ss');
                    url[props.type][props.opera]({ ...param, MAC: devices }, () => {
                        if (localStorage.getItem('modifyKey') === '1') {
                            localStorage.removeItem('modifyKey');
                            router.back();
                        }
                        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldDeliveryAuth}?type=${props.type}`);
                    });
                }
            });
            return true;
        };

        return {
            breadHeaders,
            formRef,
            formData,
            rules,
            repeatOptions,
            access,
            headers,
            requestData,
            allCheck,
            checkArray,
            dealList,
            choose,
            changeAllCheck,
            submit
        };
    }
});
